<template>
  <div class="wx-theme-page">
    <div class="toptitle">
      <div class="left">
        <div>微信主题管理</div>
      </div>
      <div class="center">
        <div>余额 {{ userCoin }} 币</div>
      </div>
      <div class="right">
        <el-button type="danger" @click="jumpThemeCncryption"
          >主题包加密</el-button
        >
        <el-button type="warning" @click="jumpThemeList">主题列表</el-button>
        <el-button type="primary" @click="jumpCodeList">兑换码列表</el-button>
      </div>
    </div>

    <el-upload
      class="upload-demo"
      ref="uploadZip"
      action="customize"
      :drag="true"
      :multiple="false"
      :show-file-list="false"
      :on-change="handleChange"
      :auto-upload="false"
      v-show="showUploadContent"
      accept=".rbt"
    >
      <div class="uploadContent">
        <div class="uploaddiv" v-show="showUploadContent">
          <el-button class="uploadbutton" type="primary"> 立刻上传</el-button>
          <div class="uploadContentTitle">
            <span class="highlight">上传前请先点击右上角将主题包加密更安全</span
            ><br />仅支持rbt格式文件
          </div>
          <!-- <el-icon class="el-icon--upload"><upload-filled /></el-icon> -->
        </div>
      </div>
    </el-upload>
    <div class="progressdiv" v-show="!showUploadContent">
      <div class="uploadContentTitle">应用正在上传中，请不要关闭浏览器...</div>
      <el-progress
        :text-inside="true"
        :stroke-width="50"
        :percentage="percentage"
        :striped="true"
        :striped-flow="true"
        :duration="5"
      />
    </div>

    <el-dialog title="设置主题名称" :visible.sync="dialogFormVisible">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item class="edit_item_title" label="主题名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item class="edit_item_title" label="备注内容" prop="note">
          <el-input v-model="form.note"></el-input>
        </el-form-item>
        <el-form-item>
          <div class="edit_bottom_btn">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="confirmAction">确 定</el-button>
          </div>
        </el-form-item>
      </el-form>

      <!-- <div class="edit_item">
        <span class="edit_item_title">主题名称：</span>

        <el-input
          v-model="name"
          autocomplete="off"
          placeholder="主题名称，必填"
        ></el-input>
      </div>

      <div class="edit_item">
        <span class="edit_item_title">主题名称：</span
        ><el-input
          v-model="note"
          autocomplete="off"
          placeholder="主题描述，非必填"
        ></el-input>
      </div>

      <div slot="footer" class="dialog-footer">
        
      </div> -->
    </el-dialog>
  </div>
</template>

<script>
import { Loading, Message } from "element-ui";
import { calculateFileMD5 } from "../../utils/file";
import {
  getPan123Token,
  getThemeList,
  postTheme,
  editTheme,
} from "../../api/wxtheme";
import { getSelf } from "@/api/member";
import apiClient from "../../utils/Pan123UploadTool";
import axios from "axios";

export default {
  name: "wxThemePage",
  components: {},
  data() {
    return {
      showUploadContent: true,
      percentage: 0,
      dialogFormVisible: false,
      file: null,
      dialognew1: false,
      inputCount: 1,
      publicCoin: 0,
      token: "",
      tokenData: {},
      preuploadID: "",
      finish: false,
      timer: null,
      chunkSize: 16777216,
      chunksCount: 1,
      userCoin: 0,
      rules: {
        name: [{ required: true, message: "请输入主题名称", trigger: "blur" }],
      },
      form: {
        name: null,
        note: "",
      },
    };
  },

  mounted() {
    this.getMemberSelf();

    const name = this.$route.query.name;
    const description = this.$route.query.description;

    if (name != undefined) {
      this.form.name = name;
    }

    if (description != undefined) {
      this.form.note = description;
    }
  },

  methods: {
    async getMemberSelf() {
      // 拿到请求数据 json
      var data = await getSelf();
      if (data != null) {
        this.userCoin = data.pay_count;
      }
    },
    async getUploadToken() {
      var tokenData = await getPan123Token();
      console.log("pan123_token", tokenData);
      this.token = tokenData["token"];
      this.tokenData = tokenData;

      if (this.token != "" && this.token != undefined) {
        this.dialogFormVisible = true;
      } else {
        alert("接口异常");
      }
    },

    handleChange(file) {
      console.log("选择的file", file);
      this.file = file;
      this.getUploadToken();
    },

    confirmAction() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.fileCreate(this.file);
          this.dialogFormVisible = false;
        }
      });
    },

    async fileCreate(file) {
      // 开始解析
      const md5 = await calculateFileMD5(file);
      const endpoint = "/upload/v1/file/create";
      const body = {
        parentFileID: this.tokenData["parent_file_id"],
        filename: this.tokenData["key"],
        etag: md5,
        size: file.size,
      };
      const headers = {
        Authorization: this.token,
      };

      const res = await apiClient({
        url: endpoint,
        data: body,
        method: "post",
        headers: headers,
      });

      this.chunkSize = res.data.sliceSize;
      this.preuploadID = res.data.preuploadID;
      if (res.data.reuse == false) {
        if (this.file.size < this.chunkSize) {
          console.log("文件可以直接上传");
          this.showUploadContent = false;
          await this.getUploadURL(file.raw);
          this.uploadComplete();
        } else {
          console.log("文件需要切片");
          this.createChunks();
        }
      } else {
        this.postThemeAfterUpload(res.data.fileID);
      }
    },

    //分片
    async createChunks() {
      const file = this.file;
      const chunkSize = this.chunkSize;
      const totalChunks = Math.ceil(file.size / chunkSize);
      console.log("分片数", totalChunks);
      this.chunksCount = totalChunks;
      this.showUploadContent = false;
      for (let i = 0; i < totalChunks; i++) {
        const start = i * chunkSize;
        const end = start + chunkSize;
        const chunk = file.raw.slice(start, end);
        await this.getUploadURL(chunk, i + 1);
      }
      this.uploadComplete();
    },

    // 获取上传的url
    async getUploadURL(fileRaw, sliceNo = 1) {
      const url = "/upload/v1/file/get_upload_url";
      const body = {
        preuploadID: this.preuploadID,
        sliceNo: sliceNo,
      };
      const headers = {
        Authorization: this.token,
      };
      return new Promise((resolve, reject) => {
        apiClient({
          url: url,
          data: body,
          method: "post",
          headers: headers,
        }).then((res) => {
          if (res.data.presignedURL != undefined) {
            axios
              .put(res.data.presignedURL, fileRaw, {
                onUploadProgress: (progressEvent) => {
                  const progress = Math.round(
                    (progressEvent.loaded / progressEvent.total) * 100
                  );
                  // console.log(`Upload Progress: ${progress}%`);
                  if (this.chunksCount == 1) {
                    this.percentage = progress;
                  } else {
                    console.log(
                      "Upload Progress:",
                      progress / this.chunksCount,
                      ((sliceNo - 1) * 100) / this.chunksCount
                    );
                    this.percentage = parseInt(
                      progress / this.chunksCount +
                        ((sliceNo - 1) * 100) / this.chunksCount
                    );
                  }
                },
              })
              .then((response) => {
                // 上传成功
                console.log("File uploaded successfully");
                resolve(response);
              })
              .catch((error) => {
                // 上传失败
                console.error("Failed to upload file:", error);
                reject(error);
                this.showUploadContent = true;
              });
          } else {
            reject(true);
          }
        });
      });
    },

    startPolling() {
      this.timer = setInterval(() => {
        if (this.finish) {
          this.stopPolling();
        } else {
          this.uploadAsyncResult();
        }
      }, 2000);
    },

    stopPolling() {
      this.finish = false;
      clearInterval(this.timer);
    },

    // 上传完毕
    async uploadComplete() {
      const url = "/upload/v1/file/upload_complete";
      const body = {
        preuploadID: this.preuploadID,
      };
      const headers = {
        Authorization: this.token,
      };

      const res = await apiClient({
        url: url,
        data: body,
        method: "post",
        headers: headers,
      });
      console.log("上传结果", res);
      if (res["code"] == 0) {
        if (res.data.completed == true) {
          this.postThemeAfterUpload(res.data.fileID);
        }
        if (res["data"]["async"] == true) {
          this.startPolling();
          console.log("开始轮询", res);
        }
      }
      this.showUploadContent = true;
    },

    // 异步轮询获取上传结果
    async uploadAsyncResult() {
      const url = "/upload/v1/file/upload_async_result";
      const body = {
        preuploadID: this.preuploadID,
      };
      const headers = {
        Authorization: this.token,
      };

      const res = await apiClient({
        url: url,
        data: body,
        method: "post",
        headers: headers,
      });

      console.log("轮询结果", res);

      if (res["code"] == 0) {
        if (res["data"]["completed"] == true) {
          console.log("上传完毕", res);
          this.finish = true;
          this.postThemeAfterUpload(res.data.fileID);
        }
      } else {
        this.finish = true;
      }
    },

    async postThemeAfterUpload(fileId) {
      const id = this.$route.query.id;
      if (id == undefined) {
        const body = {
          file_id: fileId + "",
          name: this.form.name,
          description: this.form.note,
          url_token: this.tokenData.key,
        };
        var res = postTheme(body);
        if (res != undefined) {
          Message({
            message: "上传成功!",
            type: "success",
            duration: 1000,
            offset: document.body.clientHeight * 0.5 - 100,
          });
          let that = this;
          setTimeout(function () {
            that.jumpThemeList();
          }, 1000);
        }
      } else {
        const body = {
          name: this.form.name,
          description: this.form.note,
          new_version: {
            url_token: this.tokenData.key,
            file_id: fileId + "",
          },
        };

        var res = await editTheme(id, body);
        if (res != undefined) {
          Message({
            message: "升级成功!",
            type: "success",
            duration: 1000,
            offset: document.body.clientHeight * 0.5 - 100,
          });
          let that = this;
          setTimeout(function () {
            that.jumpThemeList();
          }, 1000);
        }
      }
    },

    jumpPublish() {
      this.percentage = 50;
      this.showUploadContent = !this.showUploadContent;
    },

    jumpThemeList() {
      // var res = await getThemeList();
      // // 开始解析
      // console.log("获取的列表", res);
      this.$router.push({
        path: `/theme/list`,
      });
    },
    jumpCodeList() {
      this.$router.push({
        path: `/theme/cdkey`,
      });
    },
    jumpThemeCncryption() {
      window.open("https://tt.dumpapp.com/");
    },
  },
};
</script>

<style scoped lang="scss">
.toptitle {
  display: flex;
  height: 100px;
  width: 100%;
  align-items: center;
  background-color: #65db79;
}

.left {
  color: white;
  text-align: left;
  margin-left: 5%;
  width: 30%;
  // background-color: red;
  font-size: 20px;
  font-weight: 800;
}

.center {
  color: #e49e00;
  text-align: center;
  width: 40%;
  font-size: 18px;
  font-weight: 600;
}

.right {
  color: white;
  text-align: end;
  width: 30%;
  margin-right: 5%;
  font-size: 17px;
  font-weight: 600;
}

.wx-theme-page {
  background-color: white;
  position: relative;
  text-align: left;
  height: 100%;
}

.uploadContentTitle {
  justify-items: center;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 3; /* 设置行间距 */
}

.uploadContent {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
}

.uploaddiv {
  width: 100%;
}

.upload-demo {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-items: center;
  padding-left: 9%;
}

.uploadbutton {
  width: 130px;
  height: 40px;
  border-radius: 5px;
  background-color: #6194f2;
  font-size: 17px;
}

.progressdiv {
  display: block;
  padding-top: 20%;
  height: 80%;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  align-items: center;
  justify-items: center;
}

.edit_item_title {
  font-weight: 500;
  color: black;
  width: 100%;
}

.highlight {
  background-color: yellow; /* 高亮背景 */
  font-weight: bold; /* 可选：加粗文本 */
  padding: 2px; /* 可选：增加内边距 */
}

::v-deep .el-progress-bar__innerText {
  font-size: 20px;
  font-weight: 800;
}

::v-deep .el-upload {
  width: 90%;
  height: 80%;
  padding: 50px 5% 50px 5%;
  //   line-height: 100px;
}

::v-deep .el-upload .el-upload-dragger {
  width: 100%;
  height: 100%;
}
</style>

<template>
    <div class="background">
        <div class="logo">
            <img class="logo" src="../../assets/newlogo.png" alt="" />
        </div>
        <div class="title">主题兑换</div>
        <div class="input_cdkey">
            <el-input placeholder="输入兑换码" v-model="inputCdkey">
            <template slot="prepend">兑换码</template>
            </el-input>
        </div>
        <div class="input_wechat_id">
            <el-input placeholder="输入使用主题的微信号" v-model="inputWechatID">
            <template slot="prepend">微信号</template>
            </el-input>
        </div>
        <div class="description">* 请确保你的wx设置内有『贴贴助手』入口，没有入口请不要使用兑换码(券码)。兑换后概不退换！</div>
        <div class="description">* 兑换码仅允许使用一次，一个兑换码授权一个主题，请谨慎检查微信号格式！</div>
        <div class="description">* 如果您操作失误导致无法兑换，请携带相关依据联系主题作者！</div>

        <div class="use_button">
            <el-button type="primary" @click="useCkdey">确认信息无误，兑换。</el-button>
        </div>
    </div>
</template>
  
  <script>
  import { Loading } from "element-ui";
  import { useCdkeyApi } from "../../api/wxtheme";

  export default {
    name: "wx_cdkey",
    components: {},
    data() {
      return {
        inputCdkey: "",
        inputWechatID: ""
      };
    },
  
    mounted() {},
  
    methods: {
        async useCkdey() {
            if (this.inputCdkey == "") {
                this.$message.error('请正确输入兑换码');
                return
            }
            if (this.inputWechatID == "") {
                this.$message.error('请正确输入微信 ID');
                return
            }
            const body = {
                cdkey: this.inputCdkey,
                wechat_id: this.inputWechatID,
            };
            var response = await useCdkeyApi(body)
            if (response.error != null) {
                this.$message.error(response.error.message);
                return
            }
            this.$message({
                message: '兑换成功，请到 App 内使用',
                type: 'success'
            });
        }
    },
  };
  </script>
  
  <style scoped lang="scss">
  
  .background {
    text-align: center;
    background-color: white;
  }

  .logo {
    margin-top: 30px;
  }
  .title {
    margin-top: 40px;
    font-size: 30px;
  }

  .input_cdkey {
    margin-top: 100px;
    margin-left: 30px;
    margin-right: 30px;
  }

  .input_wechat_id {
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
  }

  .description {
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 30px;
    text-align: left;
  }

  .use_button {
    margin-top: 50px;
  }
  </style>
  
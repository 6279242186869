import request from '@/utils/request'

// 获取123云盘配置信息
export function getPan123Token () {
    return request({
      url: '/pan_123/temp_secret?suffix=rbt',
      method: 'get'
    })
}

export function getThemeList (offset) {
  return request({
    url: '/wx_theme/themes?limit=10&offset='+offset,
    method: 'get'
  })
}

export function postTheme (data) {
  return request({
    url: '/wx_theme/theme',
    method: 'post',
    data:data
  })
}

export function editTheme (themeId,data) {
  return request({
    url: '/wx_theme/theme/' + themeId ,
    method: 'put',
    data:data
  })
}

export function deleteTheme(themeId) {
  var data = {
    ids:[themeId]
  }
  return request({
    url: '/wx_theme/theme',
    method: 'delete',
    data:data
  })
}

export function getCodeList (offset) {
  return request({
    url: '/wx_theme/theme/cdkeys?limit=10&offset='+offset,
    method: 'get'
  })
}

export function deleteCode(id) {
  var data = {
    ids:[id]
  }
  return request({
    url: '/wx_theme/theme/cdkey',
    method: 'delete',
    data:data
  })
}

export function createCode(data) {
  return request({
    url: '/wx_theme/theme/cdkey',
    method: 'post',
    data:data
  })
}

export function useCdkeyApi(data) {
  return request({
    url: '/wx_theme/theme/cdkey/expense',
    method: 'post',
    data:data
  })
}